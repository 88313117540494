// Primary
export const primaryMerBlue = '#0000c2';
export const primaryMerTurquoise = '#14e6e6';

// Secondary
export const secondaryDarkTurquoise = '#3b9ac3';

// Colors
export const black = '#333333';
export const white = '#ffffff';

export const grey = '#dcdfe3';
export const lighterGrey = '#f9f9f9';
export const lightGrey = '#f2f2f2';
export const mediumGrey = '#cccccc';
export const darkGrey = '#999999';
export const midDarkGrey = '#767676';
export const darkerGrey = '#666666';

export const blue = '#4596F7';
export const lighterBlue = '#F2F8FE';
export const lightBlue = '#deedfd';
export const mediumBlue = '#b1d4f8';
export const darkBlue = '#000d82';
export const darkerBlue = '#00044a';
export const cyanBlue = '#85BCF5';

export const green = '#55b938';
export const lightGreen = '#3be783';

export const yellow = '#e8984c';
export const orange = '#F3834B';

export const red = '#e51c5c';
export const lightRed = '#fad2de';

export const purple = '#A855F7';

export const pink = '#e614aa';
export const lightPink = '#FFEBEE';
export const darkPink = '#A82693';

// Connector statuses
export const connectorStatusDefaultBackground = lighterBlue;
export const connectorStatusAvailable = '#e7f3eb';
export const connectorStatusAvailableDark = '#c8e9e5';
export const connectorStatusOccupied = '#eaeafa';
export const connectorStatusOccupiedDark = '#d5e5f9';
export const connectorStatusFaulted = lightPink;
export const connectorStatusFaultedDark = '#ffd1df';
export const connectorStatusUnavailable = lightGrey;
export const connectorStatusUnavailableDark = '#e2e2e2';
export const connectorStatusUnknown = '#fdefe8';
export const connectorStatusUnknownDark = '#fde4cc';

// KPI
export const colorPublic = secondaryDarkTurquoise;
export const colorInternal = blue;
export const colorHome = primaryMerTurquoise;
export const colorKpiCircle = '#caf7fa';

// Lists
export const listHeaderBackgroundColor = lighterGrey;
export const listHoverBackgroundColor = lighterBlue;
export const listHoverBorderColor = '#DAECFF';

// Components
export const panelBackgroundColorBlue = '#F2F8FE';
export const panelBackgroundColorGrey = lighterGrey;
export const borderColorBlue = mediumBlue;
export const borderColorGrey = '#e5e5e5';
export const tabsBorderColor = lightGrey;
export const headerBorderColor = lightGrey;
export const dividerColor = '#e5e5e5';
export const borderColorDisabled = borderColorGrey;
export const textColorDisabled = darkGrey;
export const backgroundColorDisabled = lightGrey;

// Other
export const errorColor = red;
export const successColor = green;
export const colorModalOverlay = 'rgba(33, 33, 33, 0.6)';
export const linkHoverBackgound = lightBlue;
export const iconHoverBackground = '#deedfd 20%';

export const shadowCircular = '0px 0px 2px 2px #0000000d';
export const shadowBlueSharp = '0 0 0 4px #b1d4f8';
export const shadowRedSharp = '0 0 0 1px #e51c5c';
export const menuBoxShadow = '0px 16px 24px 0px rgba(0, 0, 0, 0.08)';

export const spaceXxxs = '0.125rem'; // 2px
export const spaceXxs = '0.25rem'; // 4px
export const spaceXs = '0.5rem'; // 8px
export const spaceS = '1rem'; // 16px
export const spaceM = '1.5rem'; // 24px
export const spaceL = '2rem'; // 32px
export const spaceXl = '2.5rem'; // 40px
export const spaceXxl = '3rem'; // 48px

export const contentBoxPadding = '1.5rem 1.25rem'; // 24px 20px
export const contentBoxPaddingSmall = '0.75rem 1rem'; // 12px 16px
export const listItemPadding = '0 1.25rem'; // 0 20px

// Screen Widths
export const screenWidthMini = '500px';
export const screenWidthSmall = '768px';
export const screenWidthMedium = '1024px';
export const screenWidthLarge = '1200px';
export const maxWidthContent = '1200px';

export const readingWidth = '600px';

// Form inputs
export const inputWidth = '35.125rem'; // 562px
export const licencePlateInputWidth = '11rem'; // 176px

// Content
export const headerHeight = '4rem';
export const defaultButtonHeight = '3rem';
export const smallButtonHeight = '2rem';
export const collapsedMenuWidth = '4rem';
export const expandedMenuWidth = '16.125rem';

// Icon
export const iconSizeS = '1rem'; // 16px
export const iconSize = '1.5rem'; // 24px

// Modal
export const modalWidthSmall = '30rem';
export const modalWidthMedium = '29.5rem';

// Border radius
export const borderRadiusXs = '0.125rem'; // 2px
export const borderRadiusS = '0.25rem'; // 4px
export const borderRadiusM = '0.5rem'; // 8px

// Font Weights
export const font = {
    weight: {
        light: 300,
        regular: 500,
        semiBold: 600,
        bold: 700,
        extraBold: 800,
    },
    size: {
        subtitle: '1.125rem', // 18px
        xxl: '2rem', // 32px
        xl: '1.5rem', // 24px
        l: '1.25rem', // 20px
        m: '1rem', // 16px
        s: '0.875rem', // 14px
        xs: '0.75rem', // 12px
        xxs: '0.625rem', // 10px
    },
    lineHeight: {
        xxxl: '3rem', // 48px
        xxl: '2.5rem', // 40px
        xl: '2rem', // 32px
        l: '1.5rem', // 24px
        m: '1.25rem', // 20px
        s: '1rem', // 16px
        xs: '0.875rem', // 14px
    },
    body: "'SharpSans Medium', sans-serif",
    semiBold: "'SharpSans SemiBold', sans-serif",
    bold: "'SharpSans Bold', sans-serif",
    color: {
        default: black,
        grey: darkerGrey,
    },
};

// Transitions
export const transitionDefault = '0.3s ease-in-out';
export const transitionSnappy = '0.1s ease-in 25ms';

// Z index
export const headerZIndex = 100;
